<template>
	<div>
		<div class="flex alcenter">
			<a-input type="text" style="width: 200px;" :value="value"  placeholder="小程序链接地址"></a-input>
			<a-button type="link" @click="chooseLinkAct()">选择</a-button>
		</div>
		<!--后期补充-->
		
		<a-modal title="选择链接" :width="600" :visible="visible" @cancel="handleCancel" :footer="null">
			<div>
				<a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
				
					<a-form-model-item label="选择链接地址">
					  <a-select v-model="selectIndex" placeholder="请选择链接地址">
						<a-select-option v-for="(item,index) in  linkList" :key="index" :value="index">
						  {{item.name}}
						</a-select-option>
					  </a-select>
					</a-form-model-item>
					
					<div v-if="linkList[selectIndex].param != null">
						<a-form-model-item v-for="(item,index) in linkList[selectIndex].param" :key="index" :label="item.name">
						      <a-input v-model="linkList[selectIndex].param[index].value" />
						</a-form-model-item>
					</div>
					<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
					      <a-button type="primary" @click="onSubmit">
					        确定选择
					      </a-button>
					      
					    </a-form-model-item>
				</a-form-model>
			</div>
		</a-modal>
	</div>
</template>

<script>

	export default{
		props:{
			value:{
				type:String,
				default:''
			}
		},
		
		data(){
			return {
				selectIndex:0,
				labelCol: { span: 6 },
				wrapperCol: { span: 14 },
				visible:false,
				linkList:[
					{name:'约摄影师',link:'/pages/client/appointment/index?type=0',param:null},
					{name:'约服务',link:'/pages/client/appointment/index?type=1',param:null},
					
					{name:'商城分类',link:'/pages/client/mall/category',param:null},
			 		{name:'购物车',link:'/pages/client/mall/cart',param:null},
					{name:'秒杀列表',link:'/pages/client/mall/miaosha/index',param:null},
					{name:'拼团列表',link:'/pages/client/mall/pintuan/index',param:null},
					{name:'囤货列表',link:'/pages/client/mall/store/index',param:null},
					{name:'预售列表',link:'/pages/client/mall/booking/index',param:null},
					{name:'抢购列表',link:'/pages/client/tuan/index',param:null},
					{name:'套餐列表',link:'/pages/client/oncecard/index',param:null},
					
					{name:'买单',link:'/pages/client/buy/index',param:null},
					{name:'在线储值',link:'/pages/client/money/recharge',param:null},
					{name:'客片欣赏',link:'/pages/client/look/index',param:null},
					{name:'优惠券列表',link:'/pages/client/coupon/index',param:null},
					{name:'更多服务',link:'/pages/client/moreapp',param:null},
					{name:'关于我们',link:'/pages/client/about',param:null},
					
					{name:'商城列表',link:'/pages/client/mall/list',param:[
						{name:'分类ID',key:'category',value:''}
					]},
					{name:'商品详情',link:'/pages/client/mall/detail',param:[
						{name:'商品ID',key:'id',value:''}
					]},
					{name:'秒杀详情',link:'/pages/client/mall/miaosha/detail',param:[
						{name:'秒杀商品ID',key:'id',value:''}
					]},
					{name:'拼团详情',link:'/pages/client/mall/pintuan/detail',param:[
						{name:'拼团商品ID',key:'id',value:''}
					]},
					{name:'囤货详情',link:'/pages/client/mall/store/detail',param:[
						{name:'囤货商品ID',key:'id',value:''}
					]},
					{name:'预售详情',link:'/pages/client/mall/booking/detail',param:[
						{name:'预售商品ID',key:'id',value:''}
					]},
					{name:'跳转小程序',link:'miniapp',param:[
						{name:'小程序APPID',key:'appid',value:''}
					]},
					{name:'拨打电话',link:'tel',param:[
						{name:'电话号码',key:'tel',value:''}
					]},
					{name:'抢购详情',link:'/pages/client/tuan/detail',param:[
						{name:'详情ID',key:'id',value:''}
					]},
					{name:'套餐详情',link:'/pages/client/oncecard/detail',param:[
						{name:'详情ID',key:'id',value:''}
					]},
				],
			}
		},
		created(){
			
		},
		methods:{
			chooseLinkAct(){
				this.visible=true;
			},
			handleCancel(){
				this.visible=false;
			},
			onSubmit(){
				let link = this.linkList[this.selectIndex].link;
				if(this.linkList[this.selectIndex].param != null){
					let value = true;
					let param = new  Array;
					let params = this.linkList[this.selectIndex].param;
					for(var a in params){
						if(params[a].value == ''){
							value = false;
						}else{
							if(link == 'miniapp' || link == 'tel'){
								return this.checkIt(link + ':' + params[a].value); //直接返回
							}
							param.push(params[a].key + '=' + encodeURI(params[a].value));
						}
					}
					if(value == false){
						this.$message.error('链接的参数请填写');
						return;
					}
					return  this.checkIt(link + '?' + param.join('&'));
				}
				return this.checkIt(link);
			},
			checkIt(link){
				this.$emit('input',link);
				console.log(link);
				this.visible=false;
				return true;
			},
		}
	}
</script>

<style>
	
</style>